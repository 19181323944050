<template>
	<AddEditForm :headerTitle="$t('pages.product.addProduct')" :backTitle="$t('pages.product.backToProductPage')" :onGoBack="onGoBack" :onSave="onSave" :onSaveContinue="onSaveContinue" :showDelete="false">
        <template #content>
            <Accordion :headerTitle="$t('common.generalInfo')" v-if="editingProduct != null">
                <template v-slot:body>
                    <CForm>
                        <CInput :placeholder="$t('pages.product.enterProductName')"
                                v-model="editingProduct.name" required horizontal :is-valid="validator">
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('common.name') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>
                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.category') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <!--<select v-model="editingProduct.categoryId" class="form-control">
                                    <option selected="selected" value="0">{{ $t('pages.product.selectCategory') }}</option>
                                    <option v-for="(item) in allCategories" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select>-->
                                <multiselect :options="categoryOptions"
                                             v-model="selectedCategory"
                                             :loading="isLoadingCategory"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :clear-on-select="false"
                                             :multiple="false"
                                             :placeholder="$t('common.typetosearch')"
                                             :noOptionsText="$t('common.theListIsEmpty')"
                                             :reset-after="false"
                                             :local-search="false"
                                             @search-change="asyncFindCategory"
                                             :internal-search="false"
                                             id="ajax"
                                             label="name"
                                             track-by="id">
                                </multiselect>
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.supplier') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <select v-model="editingProduct.providerId" class="form-control">
                                    <option selected="selected" value="0">{{ $t('pages.product.selectSupplier') }}</option>
                                    <option v-for="(item) in allSuppliers" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.retailUnit') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Tên đơn vị</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Hệ số</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Giá</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;"> <div></div></th>
                                        </tr>
                                    </thead>
                                    <tbody class="position-relative">
                                        <tr v-for="(item, index ) in productUnitList" :key="index">
                                            <td>
                                                <div>
                                                    <select v-model="item.unitId" class="form-control">
                                                        <option value="0">{{ $t('pages.product.selectUnit') }}</option>
                                                        <option v-for="(item) in unitList" v-bind:key="item.id" v-bind:value="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <CInput v-model="item.conversionRate" horizontal></CInput>
                                            </td>
                                            <td>
                                                <CurrencyInput2 v-model="item.price">
                                                </CurrencyInput2>
                                            </td>
                                            <td>
                                                <a href="javascript:void(0)" @click="onRemoveUnit(item)">Xóa</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <CButton color="primary" variant="outline" square size="sm" @click="onAddUnit">Thêm</CButton>
                                            </td>
                                        </tr>
                                    </tbody><!---->
                                </table>
                            </div>
                        </div>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.wholesaleUnit') }}
                                <small class="requiredInput">(*)</small>
                            </label>
                            <div class="col-sm-9 input-group">

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Tên đơn vị</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Hệ số</div>
                                            </th>
                                            <th style="vertical-align: middle; overflow: hidden;">
                                                <div>Giá</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="position-relative">
                                        <tr>
                                            <td>
                                                <div>
                                                    <select v-model="editingProduct.wholesaleUnitId" class="form-control">
                                                        <option value="0">{{ $t('pages.product.selectUnit') }}</option>
                                                        <option v-for="(item) in unitList" v-bind:key="item.id" v-bind:value="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <CInput v-model="editingProduct.wholesaleConversionRate" horizontal></CInput>
                                            </td>
                                            <td>
                                                <CurrencyInput2 v-model="editingProduct.wholesalePrice">
                                                </CurrencyInput2>
                                            </td>
                                        </tr>
                                    </tbody><!---->
                                </table>

                                <!-- <select v-model="editingProduct.wholesaleUnitId" class="form-control">
                                    <option value="0">{{ $t('pages.product.selectwholesaleUnit') }}</option>
                                    <option v-for="(item) in availUnits" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                    </option>
                                </select> -->
                            </div>
                        </div>

                        <CInput :placeholder="$t('pages.product.enterPackagingSpecification')" v-model="editingProduct.packagingSpecification" horizontal>
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.product.packagingSpecification') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <!--<CurrencyInput v-model="editingProduct.wholesalePrice" :label="$t('pages.product.wholesalePrice')">
                            <template v-slot:headerRight>
                                <small class="requiredInput">(*)</small>
                            </template>
                        </CurrencyInput>-->

                        <CInput :placeholder="$t('pages.product.enterSKU')" v-model="editingProduct.sku" horizontal>
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.product.sku') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <CInput :label="$t('pages.product.madeIn')" :placeholder="$t('pages.product.enterMadeIn')"
                                v-model="editingProduct.madeIn" horizontal>
                        </CInput>

                        <CInput :label="$t('pages.product.certificate')" :placeholder="$t('pages.product.enterCertificate')"
                                v-model="editingProduct.certificate" horizontal>
                        </CInput>

                        <CInput :label="$t('pages.product.points')" :placeholder="$t('pages.product.enterPoints')"
                                v-model="editingProduct.points" horizontal>
                        </CInput>

                        <CInput :label="$t('pages.product.wholesalePoints')" :placeholder="$t('pages.product.enterWholesalePoints')"
                                v-model="editingProduct.wholesalePoints" horizontal>
                        </CInput>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('common.description') }}
                            </label>
                            <div class="col-sm-9">
                                <ckeditor v-model="editingProduct.description" :config="editorConfig"></ckeditor>
                            </div>
                        </div>
                    </CForm>
                </template>
            </Accordion>


            <Confirmation ref="confirmation"></Confirmation>
                      
        </template>

	</AddEditForm>
</template>




<script>
    import { mapGetters } from 'vuex'
	import { globalConfigs } from '@/global/index'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
    import Confirmation from '@/components/Confirmation.vue'
    
    import CurrencyInput2 from '@/components/CurrencyInput2.vue'
    import _ from 'lodash'

    export default {
        name: 'productadd',	
		data() {
            return {
                productUnitList: [],
				horizontal: { label:'col-3', input:'col-9' },				
				errorInput: '',
                editorConfig: globalConfigs.CKEditorConfig,    
                categoryOptions: [],
                selectedCategory: null,
                isLoadingCategory: false
			}
		},	
        components: { AddEditForm, Accordion, Confirmation, CurrencyInput2 },
        computed: {
            ...mapGetters('product', ['allProducts', 'editingProduct', 'hasError', 'errorMessage', 'unitList']),
			...mapGetters('supplier', ['allSuppliers']),
            ...mapGetters('category', ['allCategories']),
            availUnits() {
                var retVal = [];
                if (this.productUnitList != null && this.unitList != null) {
                    for (var i = 0; i < this.productUnitList.length; i++) {
                        if (this.productUnitList[i].unitId > 0) {
                            var currentUnitId = this.productUnitList[i].unitId
                            var existingUnit = _.find(this.unitList, function (o) { return o.id == currentUnitId; })
                            console.log('existing unit', existingUnit)
                            if (existingUnit != null)
                                retVal.push(existingUnit)
                        }
                    }
                }
                
                return retVal;
            }
        },
        methods: {
             validator(val) {
                return val ? val.length > 0 : false
            },

            onGoBack() {
                this.$router.push('/products/list');
            },
            onSave() {                
                this.onAddNewProduct(false);
            },
            onSaveContinue() {
                this.onAddNewProduct(true);
            },
            onCancel() {               
                this.$router.push('/products/list');
            },

            async onAddNewProduct(bContinueEdit) {                
				console.log("Add Product.......",this.editingProduct);

				if(!this.validator(this.editingProduct.name))
				{										
                    this.$store.dispatch('alert/error', this.$t('pages.product.productNameRequired'), { root: true });
                    return;					
				}

                // <@Ha
                let _categoryId = 0;
                if (this.selectedCategory != null)
                    _categoryId = this.selectedCategory.id;
               
                this.editingProduct.categoryId = _categoryId;

				if(this.editingProduct.categoryId == 0 
					|| this.editingProduct.providerId == 0
					|| this.editingProduct.sku == '')
                {                    					
                    this.$store.dispatch('alert/error', this.$t('pages.product.requiredField'), { root: true });
					return;
                }

                if (this.productUnitList.length == 0) {
                    this.$store.dispatch('alert/error', 'Thêm đơn vị tính', { root: true });
                    return;
                }
                var validUnits = _.filter(this.productUnitList, (item) => item.unitId > 0);
                if (validUnits == null || validUnits.length == 0)
                {
                    this.$store.dispatch('alert/error', 'Thêm đơn vị tính', { root: true });
                    return;
                }
                for (var i = 0; i < validUnits.length; i++) {
                    if (validUnits[i].unitId > 0) {
                        var unitPrice = parseFloat(validUnits[i].price);
                        var rate = parseFloat(validUnits[i].conversionRate);
                        if (isNaN(unitPrice) || unitPrice <= 0 || isNaN(rate) || rate <= 0) {
                            this.$store.dispatch('alert/error', 'Kiểm tra lại giá, hệ số của từng đơn vị tính. Chưa hợp lý', { root: true });
                            return;
                        }
                    }
                }

                this.editingProduct.productUnits = this.productUnitList;

                //Check wholesale unitId
                if(this.editingProduct.wholesaleUnitId <= 0){
                    this.$store.dispatch('alert/error', 'Đơn vị bán sỉ không hợp lệ', { root: true });
                    return;
                }

                if (isNaN(this.editingProduct.wholesalePrice) || this.editingProduct.wholesalePrice <= 0 || isNaN(this.editingProduct.wholesaleConversionRate) || this.editingProduct.wholesaleConversionRate <= 0){
                    this.$store.dispatch('alert/error', 'Kiểm tra lại giá, hệ số của từng đơn vị bán sỉ. Chưa hợp lý', { root: true });
                    return;
                }
                
                var retVal = await this.$store.dispatch("product/saveProduct");
                console.log("RETURN FROM ADD", retVal);
                if (retVal.success) {
                    if (bContinueEdit) {
                        this.$router.push('/products/edit/' + retVal.data);
                    }
                    else {
                        this.onGoBack();
                    }
                }
            },

            async onRemoveUnit(item) {
                var existingIdx = -1;
                for (var i = 0; i < this.productUnitList.length; i++) {
                    if (this.productUnitList[i] == item) {
                        existingIdx = i;
                        console.log('found deleted item at ' + i.toString());
                    }
                }
                if (existingIdx >= 0) {
                    this.productUnitList.splice(existingIdx, 1);
                }
            },
            async onAddUnit() {
                var emptyUnitItem = _.find(this.productUnitList, function (o) { return o.unitId == 0; });
                if (emptyUnitItem == null) {
                    this.productUnitList.push({ id: 0, unitId: 0, unitName: '', price: 0 });
                }
            },
            //<@Ha
            async asyncFindCategory(query, id) {
                if (query.length > 1) {
                    this.isLoadingCategory = true;
                    var result = await this.$categoryService.searchByName(query);
                    this.isLoadingCategory = false;
                    this.categoryOptions = result;
                }
            },
        },
        mounted() {
            this.$store.dispatch("product/initNewProduct");
			this.$store.dispatch("supplier/getAllSuppliers");	
            this.$store.dispatch("category/getAllCategories");
            this.$store.dispatch("product/getAllUnits");
        },		
    }
</script>
